@import "../globals.scss";

.container {
  min-height: 100%;
  @include tablet {
    min-height: unset;
  }
}

.innerContainer {
  display: flex;
  margin-top: 50px;
  align-items: flex-start;
  justify-content: space-between;
  @include tablet {
    flex-direction: column;
    align-items: stretch;
    margin-top: 10px;
  }
}

.left {
  h2 {
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
    color: #37436c;
    max-width: 500px;
    margin: 0 auto;
    @include tablet {
      text-align: center;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 15px;
    }
  }
  p {
    font-size: 22px;
    line-height: 33px;
    color: #7a7a7a;
    @include tablet {
      text-align: center;
      font-size: 18px;
      line-height: 25px;
    }
  }
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include tablet {
    margin-bottom: 20px;
  }
  .form {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 46px 25px;
    background: #ffffff;
    box-shadow: 0px 4px 200px rgba(177, 177, 177, 0.25);
    max-width: 435px;
    width: 100%;
  }
  form {
    display: flex;
    flex-direction: column;
    input {
      background: #f8f8f8;
      border: 1.21335px solid #dddddd;
      border-radius: 12.1335px;
      padding: 15px 20px;
      margin-bottom: 21px;
    }
    button {
      margin: 20px 0 23px;
    }
  }
}

.hr {
  display: flex;
  align-items: center;
  .line {
    flex: 1;
    background-color: #1a1a1a;
    height: 1px;
    transform: translateY(2px);
  }
  p {
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1a1a1a;
    font-weight: bold;
    padding: 0 20px;
  }
}

.social {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > div {
    cursor: pointer;
  }
}

.forgot {
  cursor: pointer;
  text-align: right;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #535353;
}

.head {
  margin-bottom: 22px;
  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 7px;
    color: #3a3a3a;
  }
  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #3a3a3a;
  }
}

.google {
  width: 100%;
  justify-content: center;
  p {
    color: #000;
    margin-left: 16px;
  }
}
