@import "../globals.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.foot {
  width: 100%;
  background: #f7f7f7;
  padding: 20px 0 63px 0;
}
.innerFoot {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
  }
}

.logo {
  display: flex;
  align-items: center;

  h6 {
    font-family: Sk-Modernist;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #2c3966;
    margin-left: 12px;
  }
}

.tag {
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  color: #7a7a7a;
  margin-top: 29px;
  @include phone {
    font-size: 15px;
  }
}
.wave {
  width: 100%;
  min-width: 2000px;
  margin-bottom: -20px;
  * {
    width: 100% !important;
  }
  img {
    object-fit: cover;
  }
}

.center {
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    margin: 10px 0;
    cursor: pointer;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
}

.socials {
  display: flex;
  margin: -10px;
  margin-top: 0px;
  .link {
    margin: 10px;
    cursor: pointer;
  }
}

.details {
  margin-top: 20px;
}
.detail {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  p {
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #2c2c2c;
  }
}
